import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Developly = () => {
  const headingbanner = {
    title: `Developly`,
    content: `A Code E-Learning Platform`,
  };

  const data = {
    images: ["developly.webp", "developly-2.webp"],
    firstAlt: "User Dashboard To Chek Their Code E-learning Status",
    firstTitle: "User Dashboard",
    secondAlt: "UI Screen To Check The Progress Of The User For A Particular Course",
    secondTitle: "Progress Screen",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: [
          "Branding",
          "UI UX Designing",
          "UI Development"
        ],
      },
      {
        title: "Platform",
        text: ["Website"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Coding is a hands-on skill that requires active practice and engagement. Being an e-learning platform for coding, it was crucial for the team to offer an interactive platform to keep the learners engaged. However, they needed help to improve their platform functionality and usability.`,
          `The platform lacked a personalized learning path to address the issues and specific learning needs of the coders. They lacked community assistance and encountered roadblocks for guidance. `,
          `Their platform was not updated with the latest industry trends which hampered the overall business objective.`
        ],
      },
      {
        title: "Solution",
        content: [
          `With our solutions, we first optimised the platform to mitigate any technical issues such as server downtime, slow loading times, system errors, etc. to enhance the user experience and avoid any disruptions. `,
          `We also updated the platform with the latest industry trends and offered personalized learning paths and adaptive content to enhance the overall platform's usability. `,
          `We also provided an option for community assistance forums to help learners seek guidance whenever they encounter any challenges. `,
        ],
      },
    ],
    image: ["developly-problem.webp"],
  };

  const projectData = {
    title: "Dashboard",
    para: [
      `This dashboard shows the overall performance of the learners along with the tracker of different courses to help learners access their performance.`,
    ],
    content: {
      image: ["developly-projectone.webp"],
    },
  };

  const projectData2 = {
    title: `Courses`,
    para: [
      `We also created a detailed view of the courses along with their level and the learner's individual rating on specific courses to let them know their skill set level.`,
    ],
    content: {
      image: ["developly-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: "Account Setup",
    para: [
      `We also created an account setup dashboard to let learners enter their detail so that the system can suggest the courses based on their skill set level. `,
    ],
    content: {
      image: [
        "developly-projectthree.webp",
        "developly-projectthree-2.webp",
        "developly-projectthree-3.webp",
        "developly-projectthree-4.webp",

      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `When we were handed over the project there were several issues that had to be addressed to deliver an interactive learning experience to the learners.`,
        `We understood the challenges of the platform and offered our solution to optimise the platform, providing an interactive learning experience with an interface to connect with the community. `,
        `Our solutions enabled the company to offer customised, updated and optimised solutions to the learners while also meeting their business objectives. `,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={22} />
      </div>
    </Layout>
  );
};

export default Developly;

export const Head = () => (
  <Seo title="Developly - Octet Design Studio" description="An automated skill testing & technical interview platform for which we did Branding, UI UX designing and UI Development." />
)